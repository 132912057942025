import './src/styles/global.css'
import './src/styles/styles.scss'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const onClientEntry = () => {
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (!(`IntersectionObserver` in window)) {
      import(`intersection-observer`)
      console.log(`# IntersectionObserver is polyfilled!`)
    }
  }